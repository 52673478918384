import React, { useEffect, useState } from 'react'
import { OrdersPresenter } from './orders-presenter'
import { orderServices } from '../../services'
import { useNavigate } from 'react-router-dom'

const OrdersContainer = () => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useState<{
    limit: string
    offset: string
    keyword: string
    filterBy: string
    sortBy: string
  }>({
    limit: '10',
    offset: '0',
    keyword: '',
    filterBy: '',
    sortBy: '',
  })
  const [pagination, setPagination] = useState({
    total: 0,
    limit: 10,
    count: 5,
    offset: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)
  const [orders, setOrders] = useState<any>([])

  useEffect(() => {
    const fetchOrders = async () => {
      const data: any = await orderServices.getOrders(searchParams)
      if (data.status === 200) {
        setOrders(data.orders)
        setPagination(data.pagination)
      }
    }
    fetchOrders()
  }, [currentPage, searchParams])

  const handleSearchParams = async ({ name, value }: { name: string; value: string }) => {
    const { limit } = pagination
    setSearchParams({
      ...searchParams,
      offset: '0',
      limit: limit.toString(),
      [name]: value,
    })
    setCurrentPage(1)
    setPagination({ ...pagination, offset: 0 })
  }

  const resetSearchParams = async () => {
    setSearchParams({
      limit: '10',
      offset: '0',
      keyword: '',
      filterBy: '',
      sortBy: '',
    })
  }

  const handleCurrentPage = (currentPage: number) => {
    const { limit } = pagination
    setCurrentPage(currentPage)
    setSearchParams({
      ...searchParams,
      offset: `${(currentPage - 1) * limit}`,
    })
    setPagination({ ...pagination, offset: (currentPage - 1) * limit })
  }

  const handleInvestmentClick = (
    opportunitySlug: string,
    opportunityId: number | string,
    investorId: number | string,
    investmentId: number | string,
  ) => {
    navigate(
      `/opportunity/${opportunitySlug}/${opportunityId}/investor/${investorId}/investment/detail/${investmentId}`,
    )
  }

  return (
    <OrdersPresenter
      pagination={pagination}
      handleCurrentPage={handleCurrentPage}
      currentPage={currentPage}
      resetSearchParams={resetSearchParams}
      searchParams={searchParams}
      orders={orders}
      handleSearchParams={handleSearchParams}
      handleInvestmentClick={handleInvestmentClick}
    />
  )
}

export { OrdersContainer }
