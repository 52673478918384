/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable arrow-spacing */
import React, { useState } from 'react'
import './navbar.scss'
import { Hamburger, CBLogoV2, Close } from '../Icon'
import { Anchor } from '../Anchor'
import { Button } from '../Button'
import { useBreakpoint } from '../../utils/hooks'
import { LG, breakpoints } from '../../utils/constants'
import { useNavigate, Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '../../../store'
import { userServices } from '../../../services'
import { resetConfiguration } from '../../../global-state/gbc-user-slice'

const navLinks = [
  // {
  //     href: '/home',
  //     text: 'Home'
  // },
  {
    href: '/opportunities',
    text: 'Opportunities',
  },
  {
    href: '/form',
    text: 'Add Opportunity',
  },
  {
    href: '/orders',
    text: 'Orders',
  },
  {
    href: '/investors',
    text: 'Investors',
  },
  {
    href: '/inquiries',
    text: 'Inquiries',
  },
]
const subLinks = [
  {
    href: '/home',
    text: 'Dashboard',
    icon: '',
  },
  {
    href: '/profile',
    text: 'Profile',
    icon: '',
  },
  {
    href: '#',
    text: 'Logout',
    icon: '',
  },
]
export const Navbar = () => {
  const [isNavExpanded, setIsNavExpanded] = useState(false)
  const { authToken, email } = useSelector((state: RootState) => state.gbcUser)
  const loggedIn = authToken && authToken
  const { breakpoint } = useBreakpoint()
  const dispatch = useDispatch()
  const logoutHandler = async () => {
    const data: any = await userServices.userLogout({
      Authorization: authToken ? authToken : '',
    })
    if (data.status === 200) {
      dispatch(resetConfiguration())
      navigate('/opportunities')
      window.location.reload()
    }
  }
  const navigate = useNavigate()
  return (
    <div className={isNavExpanded ? 'navigation-wrapper' : ''}>
      <nav className='navigation'>
        <Link to={'/opportunities'}>
          {' '}
          <CBLogoV2 />{' '}
        </Link>
        <button
          className='hamburger'
          onClick={() => {
            setIsNavExpanded(!isNavExpanded)
          }}
        >
          {!isNavExpanded && <Hamburger />}
          {isNavExpanded && <Close />}
        </button>
        <div className={isNavExpanded ? 'navigation-menu expanded' : 'navigation-menu'}>
          <ul className='nav-list'>
            <>
              {loggedIn &&
                navLinks.map(({ href, text }) => (
                  <li
                    className='nav-item'
                    key={href}
                    onClick={() => {
                      if (isNavExpanded) setIsNavExpanded(false)
                    }}
                  >
                    <Anchor href={href} text={text} variant='nav-link' />
                  </li>
                ))}
              {loggedIn && (
                <>
                  <li className='nav-item'>
                    {!isNavExpanded ? (
                      <Button
                        text='Logout'
                        classes={
                          breakpoint && breakpoint > LG ? ['mr-8', 'navbar-btn-padding'] : ['mb-8']
                        }
                        variant='secondary-btn'
                        handleClick={logoutHandler}
                      />
                    ) : (
                      <div onClick={logoutHandler}>
                        <strong>
                          <Anchor href='' text='Logout' variant='nav-link-bold' />
                        </strong>{' '}
                      </div>
                    )}
                  </li>
                </>
              )}
            </>
          </ul>
        </div>
      </nav>
    </div>
  )
}
