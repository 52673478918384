import './App.scss'
import React, { useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {
  LoginContainer,
  ForgotPasswordContainer,
  ResetInstructionsContainer,
  ResetPasswordContainer,
  ProjectDetailContainer,
  NotFound,
  AccessDenied,
  ProjectListContainer,
  ProjectFormContainer,
  InvestorList,
  ProfileDetailsContainer,
  InvestmentUserListContainer,
  InvestmentProjectListContainer,
  InvestmentDetailContainer,
  QueriesList,
  HomeFormContainer,
  OrdersContainer,
} from './pages'
import { Navbar, Footer } from './lib/components'
import { PublicRoute, PrivateRoute } from './route-permissions'
import { OpportunityListContainer } from './pages/opportunities/opportunity-list-container'

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <div className='App'>
        <Routes>
          {['/', '/login'].map((path) => (
            <Route
              key={path}
              path={path}
              element={
                <PublicRoute>
                  <LoginContainer />
                </PublicRoute>
              }
            />
          ))}
          <Route
            path='forgot-password'
            element={
              <PublicRoute>
                <ForgotPasswordContainer />
              </PublicRoute>
            }
          />
          <Route
            path='reset-instructions'
            element={
              <PublicRoute>
                <ResetInstructionsContainer />
              </PublicRoute>
            }
          />
          <Route
            path='reset-password'
            element={
              <PublicRoute>
                <ResetPasswordContainer />{' '}
              </PublicRoute>
            }
          />
          <Route
            path='form'
            element={
              <PrivateRoute>
                <ProjectFormContainer />{' '}
              </PrivateRoute>
            }
          />
          <Route
            path='home'
            element={
              <PrivateRoute>
                <HomeFormContainer />{' '}
              </PrivateRoute>
            }
          />
          <Route
            path='form/:projectSlug/:id'
            element={
              <PrivateRoute>
                <ProjectFormContainer />
              </PrivateRoute>
            }
          />
          <Route
            path='opportunity/:projectSlug/:id'
            element={
              <PrivateRoute>
                <ProjectDetailContainer />
              </PrivateRoute>
            }
          />
          <Route
            path='investors'
            element={
              <PrivateRoute>
                <InvestorList />
              </PrivateRoute>
            }
          />
          <Route
            path='investor/:investorId/profile'
            element={
              <PrivateRoute>
                <ProfileDetailsContainer />
              </PrivateRoute>
            }
          />
          <Route
            path='opportunity/investments/:projectSlug/:projectId'
            element={
              <PrivateRoute>
                <InvestmentUserListContainer />
              </PrivateRoute>
            }
          />
          <Route
            path='opportunity/:projectSlug/:projectId/investor/:investorId/investment/detail/:investmentId'
            element={
              <PrivateRoute>
                <InvestmentDetailContainer />
              </PrivateRoute>
            }
          />
          <Route
            path='user/investments/:investorId'
            element={
              <PrivateRoute>
                <InvestmentProjectListContainer />
              </PrivateRoute>
            }
          />
          <Route path='/403' element={<AccessDenied />} />
          <Route path='*' element={<NotFound />} />
          {/* <Route path="opportunities" element={<PrivateRoute><ProjectListContainer /></PrivateRoute>} /> */}
          <Route
            path='opportunities'
            element={
              <PrivateRoute>
                <OpportunityListContainer />
              </PrivateRoute>
            }
          />
          <Route
            path='inquiries'
            element={
              <PrivateRoute>
                <QueriesList />
              </PrivateRoute>
            }
          />
          <Route
            path='orders'
            element={
              <PrivateRoute>
                <OrdersContainer />
              </PrivateRoute>
            }
          />
        </Routes>
      </div>
      <Footer />
    </BrowserRouter>
  )
}

export default App
