import React from 'react'
import { displayDollar, getFormattedDate } from '../../lib/utils/helpers'
import { EyeIcon, ToolTipBlack } from '../../lib/components'
import './order.scss'
import { stuckAtInvestmentMessage } from '../../lib/utils/message'
import { OrderCardProps } from '../../lib/types'
function OrderCard({
  order_id,
  opportunity_title,
  order_creation_date,
  investor_portfolio_id,
  investor_name,
  investor_email,
  currency_code,
  investment_amount,
  total_amount_to_be_transferred,
  investment_completed,
  completed_steps,
  handleInvestmentClick,
  unit_allocation_date,
}: OrderCardProps) {
  const formattedDate = (date: string) => getFormattedDate(new Date(date), 'D MMM YYYY [at] h:mm A')
  return (
    <tr key={order_id} className={investment_completed ? '' : 'incomplete-order'}>
      <td>{order_id}</td>
      <td>{opportunity_title}</td>
      <td>{order_creation_date ? formattedDate(order_creation_date) : 'Not available'}</td>
      <td>{unit_allocation_date ? formattedDate(unit_allocation_date) : 'Not available'}</td>
      <td>{investor_portfolio_id}</td>
      <td>{investor_name}</td>
      <td>{investor_email}</td>
      <td>{displayDollar(investment_amount.toString(), currency_code)}</td>
      <td>{displayDollar(total_amount_to_be_transferred.toString(), currency_code)}</td>
      <td>
        <div className={'table-cell-with-icon'}>
          {investment_completed ? 'Yes' : 'No'}
          {!investment_completed && (
            <ToolTipBlack variant='pending-icon' classes={['ml-8']}>
              {stuckAtInvestmentMessage[Number(completed_steps) + 1]?.stepDescription}
            </ToolTipBlack>
          )}
        </div>
      </td>
      <td>
        <EyeIcon
          classes={['align-center', 'pointer']}
          height='24px'
          width='24px'
          onClick={() => handleInvestmentClick()}
        />
      </td>
    </tr>
  )
}
export { OrderCard }
