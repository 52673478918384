import { Role } from '../lib/types'
export const BASE_URL = process.env.REACT_APP_GBC_BASE_URL || ' http://0.0.0.0:4000'

// User and auth routes
export const USER_SIGN_IN = `${BASE_URL}/auth/login`
export const SIGN_OUT = `${BASE_URL}/auth/logout`
export const SEND_VERIFICATION_LINK = `${BASE_URL}/user/resend/verify-email`
export const RESET_PASSWORD = `${BASE_URL}/user/reset-password`
export const UPLOAD_FILE = `${BASE_URL}/file/upload`
export const UPLOAD_FUND_ALLOCATION = `${BASE_URL}/file/job`
export const UPLOAD_QUARTERLY_REPORT_JOB = `${BASE_URL}/file/job`
export const GET_JOB_LIST = `${BASE_URL}/file/jobs`
export const HOME = `${BASE_URL}/home`
export const HOME_UPDATE = `${BASE_URL}/home/update`
export const USER_LOGOUT = `${BASE_URL}/auth/logout`
export const PREVIEW_FILE = `${BASE_URL}/file/preview`
export const CREATE_NEW_PROJECT = `${BASE_URL}/project/new`
export const GET_ALL_PROJECTS = `${BASE_URL}/project/all`
export const GET_PROJECT_BY_ID = `${BASE_URL}/project`
export const SEARCH_PROJECTS = `${BASE_URL}/project/search`
export const UPDATE_PROJECT = `${BASE_URL}/project/update`
export const DELETE_PROJECT = `${BASE_URL}/project/delete`

// Investor routes
export const INVESTORS_LIST = (roles: Role) => `${BASE_URL}/user/all?roles=${roles}`
export const INVESTOR_PROFILE = (investorId: number) => `${BASE_URL}/user/${investorId}/profile`
export const LOCK_UNLOCK_ACCOUNT = (investorId: number) => `${BASE_URL}/user/update/${investorId}`

// KYC routes
export const SET_INVESTMENT_LIMIT = `${BASE_URL}/kyc/investment-limit`
export const GET_KYC_DOCUMENTS = (investorId: number) => `${BASE_URL}/kyc/${investorId}`
export const COMMENT_OR_APPROVE_KYC_DOCUMENT = (documentId: number) =>
  `${BASE_URL}/kyc/document/approve/${documentId}`
export const UPDATE_KYC = `${BASE_URL}/kyc/update`

// Investment routes
export const SEARCH_INVESTMENTS = `${BASE_URL}/investments/search`
export const GET_INVESTMENT_DETAIL = `${BASE_URL}/investments`
export const UPDATE_INVESTMENT_STATUS = `${BASE_URL}/investments/status`
export const GET_INVESTMENT_DETAIL_FOR_INVESTOR = `${BASE_URL}/investments/user/details`
export const UPLOAD_QUARTERLY_REPORT = `${BASE_URL}/investments/quaterly-reports`

// Queries route
export const SEARCH_QUERIES = `${BASE_URL}/contact-us/search`

// v1

// Investor routes
export const INVESTORS_LIST_V1 = `${BASE_URL}/v1/user/all`

// Investment routes
export const SET_INVESTMENT_LIMIT_V1 = `${BASE_URL}/v1/user/investment-limit`

export const USER_SIGN_IN_V1 = `${BASE_URL}/v1/auth/login`
export const USER_LOGOUT_V1 = `${BASE_URL}/v1/auth/logout`
export const SEARCH_OPPORTUNITIES = `${BASE_URL}/v1/opportunity/search`
export const GET_OPPORTUNITY_BY_ID = `${BASE_URL}/v1/opportunity`
export const CREATE_NEW_OPPORTUNITY = `${BASE_URL}/v1/opportunity`
export const UPDATE_OPPORTUNITY = `${BASE_URL}/v1/opportunity`
export const GET_INVESTMENT_BY_ID = `${BASE_URL}/v1/investments`
export const UPDATE_INVESTMENT_STATUS_V1 = `${BASE_URL}/v1/investments/status`
export const SEARCH_INVESTMENTS_V1 = `${BASE_URL}/v1/investments/search`
export const GET_KYC_DOCUMENTS_V1 = (investorId: number) => `${BASE_URL}/v1/kyc/${investorId}`
export const INVESTOR_PROFILE_v1 = (investorId: number) =>
  `${BASE_URL}/v1/user/${investorId}/profile`
export const GET_INVESTMENT_DETAIL_FOR_INVESTOR_V1 = (investorId: number) =>
  `${BASE_URL}/v1/investments/investor/${investorId}`
export const SEND_VERIFICATION_LINK_V1 = `${BASE_URL}/v1/user/resend/verify-email`

// Queries route
export const SEARCH_QUERIES_V1 = `${BASE_URL}/v1/contact-us/search`

// Orders route
export const SEARCH_ORDERS = `${BASE_URL}/v1/orders`
