import { axiosInstance } from '.'
import { SearchParams } from '../lib/types'
import { removeEmptyValueFromObject } from '../lib/utils/helpers'
import { SEARCH_ORDERS } from './endpoints'

export const orderServices = {
  getOrders: async (params: any) => {
    try {
      const data = await axiosInstance.get(`${SEARCH_ORDERS}`, {
        params: removeEmptyValueFromObject(params),
      })
      const orders = {
        orders: data.data.orders,
        pagination: {
          count: data.data.count,
          limit: data.data.limit,
          offset: data.data.offset,
          total: data.data.total,
        },
        status: data.status,
      }

      return orders
    } catch (error: any) {
      return error.response
    }
  },
}
