import React, { useState } from 'react'
import { Popover } from 'react-tiny-popover'
import './tool-tip-component.scss'
import { ToolTipProps, ToolTipBlackProps } from '../../types'
import { InfoIcon, PendingIcon } from '../Icon'
import { concatClassName } from '../../utils/helpers'

const ToolTip = ({ content, children }: ToolTipProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const showToolTip = () => {
    setIsOpen(true)
  }

  const closeToolTip = () => {
    setIsOpen(false)
  }

  const toolTipContent = () => {
    return <div className={'tool-tip-container'}>{content}</div>
  }

  return (
    <div className={'pointer'} onMouseOver={showToolTip} onMouseLeave={closeToolTip}>
      <Popover
        isOpen={isOpen}
        positions={['top', 'bottom', 'right', 'left']}
        content={toolTipContent}
      >
        {children}
      </Popover>
    </div>
  )
}

const ToolTipBlack = ({ variant, classes, children }: ToolTipBlackProps) => {
  return (
    <div className={`${concatClassName(classes)}`}>
      <div className='tooltip'>
        {variant === 'pending-icon' && (
          <PendingIcon classes={['info-icon']} width='20px' height='24px' />
        )}

        {variant === 'info-icon' && <InfoIcon classes={['info-icon']} width='20px' height='24px' />}
        <span className='tooltiptext'>{children}</span>
      </div>
    </div>
  )
}

export { ToolTip, ToolTipBlack }
