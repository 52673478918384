import axios from 'axios'

axios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken')
    if (token) config.headers = { Authorization: token }
    return config
  },
  (error) => Promise.reject(error),
)

axios.interceptors.response.use(
  (response) => {
    if (!response.data.error) return response
  },
  (error) => {
    // when any Api return "Unauthorized" error then reloading the page and clearing localStorage and sessionStorage
    if (error.response.status === 401 && error.response.data.error === 'Unauthorized') {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
    }
    return Promise.reject(error)
  },
)

export const axiosInstance = axios

export { userServices } from './user-service'
export { projectServices } from './project-service'
export { investorServices } from './investor-services'
export { kycServices } from './kyc-services'
export { investmentServices } from './investment-services'
export { jobServices } from './jobs-services'
export { homeServices } from './home-service'
export { orderServices } from './orders-services'
